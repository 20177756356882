import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, from, of, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  /**
   * @param {Router} _router
   * @param {AuthenticationService} _authenticationService
   */
  constructor(private _router: Router) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      //Reintentar 3 veces cuando el servicio responda un 504
     // retry({ delay: (error:HttpErrorResponse,retries:number) => error.status == 504 && retries < 3 ? next.handle(request) : throwError(() => error) }),
      catchError((err: HttpErrorResponse) => {
        if ([401, 403].indexOf(err.status) !== -1) {
          // Redirigir a login en caso de no estar autorizado o que el token haya expirado
          this._router.navigate(['/auth']);
        }
        // throwError
        const error = err.error.message || err.statusText;
        return throwError(() => new HttpErrorResponse({ status: err.status, error: error}));
      })
    );
  }
}
