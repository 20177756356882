import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from "../../../environments/environment";
//Modules
import { NgbCollapseModule, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastModule } from 'primeng/toast';
import { NgxSpinnerModule } from 'ngx-spinner';

//Componentes
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { PagesComponent } from './pages.component';

//Directives
import { MouseOutDirective } from 'directives/mouse-out.directive';

@NgModule({
  declarations: [
    FooterComponent,
    HeaderComponent,
    PagesComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    //NgBootstrap
    NgbCollapseModule,
    NgbModalModule,
    NgxSpinnerModule,
    ToastModule,
    //Directives
    MouseOutDirective,
    ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.SW })
  ],
  exports: [
    FooterComponent,
    HeaderComponent,
    PagesComponent
  ]
})
export class LayoutModule { }
