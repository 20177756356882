import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from 'environment';
import { AuthenticationService } from 'services/authentication.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  /**
   *
   * @param {AuthenticationService} _authenticationService
   */
  constructor(private _authenticationService: AuthenticationService) {}

  /**
   * Add auth header with jwt if user is logged in and request is to api url
   * @param request
   * @param next
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const currentUser = this._authenticationService.currentUserValue;
    const isLoggedIn = currentUser && currentUser.token;
    if (isLoggedIn) {
      let agregaTokenURL = true;
      const txtUrlsExcluidas = [
        '.s3.amazonaws.com',
        '.s3.us-east-1.amazonaws.com'
      ];

      // Determina si a la url no se le debe agregar el token
      for (const txt of txtUrlsExcluidas) {
        if (request.url.split(txt).length > 1) {
          agregaTokenURL = false;
          break;
        }
      }

      if (agregaTokenURL) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${currentUser.token}`
          }
        });
      }
    }

    return next.handle(request);
  }
}
