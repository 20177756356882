import { APP_INITIALIZER, ApplicationConfig, importProvidersFrom, isDevMode } from '@angular/core';
import { DATE_PIPE_DEFAULT_OPTIONS, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';
import { provideRouter } from '@angular/router';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideServiceWorker } from '@angular/service-worker';
import localeEsCL from "@angular/common/locales/es-CL";


// Modules
import { ToastrModule } from 'ngx-toastr';
import { MessageService } from 'primeng/api';

import { routes } from './app.routes';

import 'dayjs/locale/es';
import dayjs from 'dayjs';
import tz from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import { KeycloakService } from 'services/keycloak.service';
import { JwtInterceptor } from 'interceptors/jwt.interceptor';
import { ErrorInterceptor } from 'interceptors/error.interceptor';
import { provideDaterangepickerLocale } from "ngx-daterangepicker-bootstrap";
//Init locale
registerLocaleData(localeEsCL);
dayjs.locale('es');

dayjs.extend(utc);
dayjs.extend(tz);
dayjs.tz.setDefault("America/Santiago");
const globalTimezone = dayjs.tz().format('ZZ');
const currentTimezone = dayjs.tz.guess();
console.log('Current Timezone', globalTimezone, currentTimezone)
export function kcFactory(keycloakService: KeycloakService) {
  return () => keycloakService.init();
}


export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
    {
      provide:HTTP_INTERCEPTORS,
      useClass:JwtInterceptor,
      multi:true
    },
    {
      provide:HTTP_INTERCEPTORS,
      useClass:ErrorInterceptor,
      multi:true
    },
    {
        provide: APP_INITIALIZER,
        useFactory: kcFactory,
        deps: [KeycloakService],
        multi: true,
    },
    importProvidersFrom(ToastrModule.forRoot({
        positionClass: 'toast-top-center',
        preventDuplicates: true,
        closeButton: true
    })),
    MessageService,
    provideServiceWorker('ngsw-worker.js', {
       // enabled: true,
        enabled: !isDevMode(),
        registrationStrategy: 'registerWhenStable:30000'
    }),
    { provide: DATE_PIPE_DEFAULT_OPTIONS, useValue: {timezone: globalTimezone} },
    provideDaterangepickerLocale({
      separator: ' - ',
      applyLabel: 'Okay',
    })
]};
